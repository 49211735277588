<template>
  <div>
    <AtomLabel
      v-if="label"
      :label="preparedLabel"
      :class="[labelClasses, 'mb-3']"
    />
    <div class="image-input">
      <div
        class="image-input__body"
        @click.self="selectHandler"
      >
        <span class="image-input__caption">{{ $t('image.action.upload') }}</span>
      </div>
      <div
        class="image-input__image"
        ref="image"
        @click.self="selectHandler"
        :style="{ display: src ? 'block' : 'none', backgroundImage: `url(${src})` }"
      >
        <AtomButton
          class="image-input__clear"
          color="danger"
          @click="clearValue"
        >
          {{ $t('image.action.clear') }}
        </AtomButton>
      </div>
      <input
        class="image-input__input"
        ref="input"
        accept="image/*"
        type="file"
        @change="updateValue($event.target.value)"
      >
    </div>
  </div>
</template>

<script>
import { fileReader } from '@/helpers'

import AtomLabel from '@/components/AtomLabel'
import AtomButton from '@/components/AtomButton'

import FormMixin from '@/mixins/FormMixin'
import InputMixin from '@/mixins/InputMixin'

export default {
  name: 'MoleculeImageInput',

  components: {
    AtomLabel,
    AtomButton,
  },

  mixins: [FormMixin, InputMixin],

  props: {
    value: [String, File],
    prefill: String,
  },

  data: () => ({
    src: '',
  }),

  watch: {
    prefill (value) {
      this.src = value
    },
  },

  methods: {
    selectHandler () {
      this.$refs.input.click()
    },

    clearValue () {
      this.src = ''
      this.$refs.input.value = ''
      this.updateValue(null)
    },

    async updateValue (value) {
      if (this.$refs.input.files[0]) {
        this.$emit('input', this.$refs.input.files[0])
        const src = await fileReader(this.$refs.input.files[0])
        this.src = src
      } else {
        this.$emit('input', value)
      }
    },
  },
}
</script>

<style lang="scss" scoped>
  @import "~bootstrap/scss/functions";
  @import '~@tabler/core/src/scss/variables';

  .image-input {
    position: relative;
    width: 100%;
    padding-top: 100%;
    background-color: $gray-100;
    user-select: none;

    .theme-dark & {
      background-color: $dark-mode-darken;
    }

    &:hover {
      cursor: pointer;
    }

    &__caption {
      pointer-events: none;
      color: $gray-300;
      font-size: 1.25rem;
    }

    &__body {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      outline: 4px dashed $gray-300;
      outline-offset: -10px;

      .theme-dark & {
        outline-color: $dark-mode-lighten;
      }
    }

    &__image {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background-size: cover;
      background-position: center center;
      background-repeat: no-repeat;
    }

    &__input {
      display: none;
    }

    &__clear {
      position: absolute;
      top: 1rem;
      right: 1rem;
    }
  }
</style>
