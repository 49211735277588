<template>
  <div>
    <AtomLabel
      v-if="label"
      :label="preparedLabel"
      :class="labelClasses"
    />
    <quillEditor
      ref="content"
      :id="id"
      :style="{ minHeight: `${height}px` }"
      :options="editorOptions"
      :class="inputClasses"
      :disabled="isDisabled"
      :content="value"
      @change="updateValue($event)"
    />
    <small
      v-if="description || !!$slots.description"
      class="form-hint mt-2"
    >
      <slot name="description">{{ description }}</slot>
    </small>
    <span
      v-if="error"
      class="invalid-feedback"
      v-text="error"
    />
  </div>
</template>

<script>
import AtomLabel from '@/components/AtomLabel'

import FormMixin from '@/mixins/FormMixin'
import InputMixin from '@/mixins/InputMixin'

import { quillEditor } from 'vue-quill-editor'

export default {
  name: 'AtomRichtext',

  components: {
    AtomLabel,
    quillEditor,
  },

  mixins: [FormMixin, InputMixin],

  props: {
    options: Object,

    height: {
      type: Number,
      default: 300,
    },
  },

  computed: {
    editor () {
      return this.$refs.content?.quill
    },

    editorOptions () {
      return {
        placeholder: this.$t('content.placeholder'),
        theme: 'snow',
        modules: {
          toolbar: [
            [{ header: [1, 2, 3, 4, 5, 6, false] }],
            ['bold', 'italic', 'underline', 'strike'],
            ['blockquote'],
            [{ list: 'ordered' }, { list: 'bullet' }],
            ['link'],
            ['clean'],
          ],
        }
      }
    },
  },

  watch: {
    isLoading (value) {
      this.editor.disable(value)
    },
  },

  methods: {
    updateValue ({ html }) {
      this.$emit('input', html)
    },
  },
}
</script>

<style lang="scss">
  @import "~bootstrap/scss/functions";
  @import '~@tabler/core/src/scss/variables';
  @import '~quill/dist/quill.core.css';
  @import '~quill/dist/quill.snow.css';

  .quill-editor {
    .ql {
      $self: &;
      $snow: '.ql-snow';

      &-container {
        min-height: inherit;

        &#{$snow} {
          border-top: 0;
          border-bottom-right-radius: $border-radius;
          border-bottom-left-radius: $border-radius;
        }
      }

      &-disabled {
        opacity: .5;
      }

      &-toolbar {
        &#{$snow} {
          border-top-left-radius: $border-radius;
          border-top-right-radius: $border-radius;
        }
      }

      &-editor {
        &.ql-blank::before {
          left: auto !important;
          right: auto !important;
          font-style: normal !important;
        }
      }

      &-quill.ql-quil-plain {
        #{$self}-toolbar {
          padding: 0;
          margin: 0;
          border: 0;

          &::after {
            display: none;
          }

          #{$self}-picker-label {
            padding-left: 0;
          }
        }

        #{$self}-container {
          border: 0;
        }

        #{$self}-editor {
          border: 0;
          padding: 0;
        }
      }
    }
  }

  .ql-snow .ql-editor pre.ql-syntax {
    overflow: visible;
    border-radius: $border-radius;
  }

  .modal .ql-snow .ql-tooltip.ql-editing {
    left: 20px !important;
  }

  .ql-snow .ql-tooltip {
    border: 0;
    padding: .5rem 1rem;
    box-shadow: $shadow-card-hover;
    border-radius: $border-radius;
  }

  .ql-snow .ql-tooltip input[type=text] {
    border: 0;
    background-color: transparent;
    outline: 0 !important;
    box-shadow: none;
    border-radius: 0;
    border: 1px solid #2b2b40;
    color: #92929f;
    outline: 0 !important;
    border-radius: $border-radius;
  }

  .ql-snow .ql-tooltip input[type=text]:active,
  .ql-snow .ql-tooltip input[type=text]:focus {
    border-color: #474761 !important;
  }

  .ql-snow .ql-tooltip .ql-preview {
    color: #6d6d80;
  }

  .ql-snow .ql-tooltip .ql-action {
    transition:color .3s ease;
    color: #6d6d80;
  }

  .ql-snow .ql-tooltip .ql-action:hover {
    transition: color .3s ease;
    color: $primary;
  }

  .ql-snow .ql-picker .ql-picker-label {
    color: #565674;
  }

  .ql-snow .ql-picker .ql-picker-label.ql-active,
  .ql-snow .ql-picker .ql-picker-label:hover {
    color: $primary;
  }

  .ql-snow .ql-picker.ql-expanded {
    outline: 0 !important;
    border-color: transparent !important;
  }

  .ql-snow .ql-picker.ql-expanded .ql-picker-label {
    border-color: transparent !important;
    color: $primary;
    outline: 0 !important;
  }

  .ql-snow .ql-picker.ql-expanded .ql-picker-label.ql-active,
  .ql-snow .ql-picker.ql-expanded .ql-picker-label:hover {
    color: $primary;
  }

  .ql-snow .ql-picker.ql-expanded .ql-picker-options {
    border: 0;
    padding: .5rem 1rem;
    box-shadow: $shadow-card-hover;
    border-radius: $border-radius;
  }

  .ql-snow .ql-picker.ql-expanded .ql-picker-options .ql-picker-item {
    outline: 0;
  }

  .ql-snow .ql-picker.ql-expanded .ql-picker-options .ql-picker-item.ql-active,
  .ql-snow .ql-picker.ql-expanded .ql-picker-options .ql-picker-item.ql-selected,
  .ql-snow .ql-picker.ql-expanded .ql-picker-options .ql-picker-item:hover {
    color: $primary !important;
  }

  .ql-snow .ql-editor pre.ql-syntax {
    background-color: $white;
    color: #565674;
  }

  .is-invalid {
    .ql-toolbar {
      border-bottom-color: var(--tblr-red);
    }

    .ql-container {
      border-color: var(--tblr-red);
    }
  }

  .theme-dark {
    .ql-toolbar.ql-snow {
      border-color: $dark-mode-border-color;
    }

    .ql-toolbar.ql-snow .ql-picker .ql-fill,
    .ql-toolbar.ql-snow .ql-picker .ql-stroke,
    .ql-toolbar.ql-snow button .ql-fill,
    .ql-toolbar.ql-snow button .ql-stroke {
      stroke: $dark-mode-text;
    }

    .ql-toolbar.ql-snow .ql-picker .ql-fill,
    .ql-toolbar.ql-snow button .ql-fill {
      fill: $dark-mode-text;
    }

    .ql-toolbar.ql-snow .ql-picker.ql-active .ql-fill,
    .ql-toolbar.ql-snow .ql-picker.ql-active .ql-stroke,
    .ql-toolbar.ql-snow .ql-picker.ql-expanded .ql-fill,
    .ql-toolbar.ql-snow .ql-picker.ql-expanded .ql-stroke,
    .ql-toolbar.ql-snow .ql-picker:focus .ql-fill,
    .ql-toolbar.ql-snow .ql-picker:focus .ql-stroke,
    .ql-toolbar.ql-snow .ql-picker:hover .ql-fill,
    .ql-toolbar.ql-snow .ql-picker:hover .ql-stroke,
    .ql-toolbar.ql-snow button.ql-active .ql-fill,
    .ql-toolbar.ql-snow button.ql-active .ql-stroke,
    .ql-toolbar.ql-snow button.ql-expanded .ql-fill,
    .ql-toolbar.ql-snow button.ql-expanded .ql-stroke,
    .ql-toolbar.ql-snow button:focus .ql-fill,
    .ql-toolbar.ql-snow button:focus .ql-stroke,
    .ql-toolbar.ql-snow button:hover .ql-fill,
    .ql-toolbar.ql-snow button:hover .ql-stroke {
      stroke: $primary;
    }

    .ql-toolbar.ql-snow .ql-picker.ql-active .ql-fill,
    .ql-toolbar.ql-snow .ql-picker.ql-expanded .ql-fill,
    .ql-toolbar.ql-snow .ql-picker:focus .ql-fill,
    .ql-toolbar.ql-snow .ql-picker:hover .ql-fill,
    .ql-toolbar.ql-snow button.ql-active .ql-fill,
    .ql-toolbar.ql-snow button.ql-expanded .ql-fill,
    .ql-toolbar.ql-snow button:focus .ql-fill,
    .ql-toolbar.ql-snow button:hover .ql-fill {
      fill: $primary;
    }

    .ql-editor {
      color: $dark-mode-text;
    }

    .ql-editor.ql-blank:before {
      color: $input-placeholder-color !important;
    }

    .ql-container.ql-snow {
      background-color: $dark-mode-darken;
      border-color: $dark-mode-border-color;
    }

    .ql-snow .ql-picker.ql-expanded .ql-picker-options {
      background-color: $dark-mode-darken;
    }

    .ql-snow .ql-picker.ql-expanded .ql-picker-options .ql-picker-item {
      color: $dark-mode-text;
    }
  }
</style>
