<template>
  <div class="page-wrapper">
    <MoleculePageHeader
      :pretitle="article.title ? pretitle : ''"
      :title="article.title || pretitle"
    >
      <AtomButton
        @click.prevent="$router.go(-1)"
        class="d-none d-sm-inline-block"
      >
        <arrow-left-icon />{{ $t('action.back') }}
      </AtomButton>
    </MoleculePageHeader>
    <MoleculeForm
      class="page-body"
      :validator="$v"
      :disabled="state.submitLoading"
    >
      <div class="container-xl">
        <div class="row row-cards">
          <div class="col-12 col-lg-8">
            <MoleculeCard :loading="state.loading">
              <AtomInput
                class="mb-3"
                v-model.trim="article.title"
                label="title"
                :counter="true"
              />
              <AtomTextarea
                class="mb-3"
                v-model.trim="article.introtext"
                :counter="true"
                :rows="6"
                label="introtext"
              />
              <AtomRichtext
                class="mb-3"
                v-model="article.content"
                label="content"
                :height="300"
              />
            </MoleculeCard>
          </div>
          <div class="col-12 col-lg-4">
            <MoleculeCard :loading="state.loading">
              <AtomSelect
                class="mb-3"
                v-model="article.categoryKey"
                label="category"
                :options="categories"
                :searchable="true"
                :clearable="false"
              />
              <AtomDatePicker
                class="mb-3"
                v-model="article.publishedAt"
                label="publishedAt"
              />
              <AtomChoose
                v-model="article.published"
                label="published"
                type="switch"
                label-class="fw-bold"
              />
              <AtomDivider />
              <MoleculeImageInput
                class="mb-3"
                v-model="article.image"
                label="image"
                :prefill="article.imageUrl"
              />
              <div class="mb-2">
                <div class="btn-list">
                  <AtomButton
                    color="primary"
                    class="w-100"
                    :disabled="!state.dirty || $v.$anyError"
                    @click="submitHandler(true)"
                  >
                    {{ $t('action.save_and_view') }}
                  </AtomButton>
                  <AtomButton
                    color="primary"
                    variant="outline"
                    class="w-100"
                    :disabled="!state.dirty || $v.$anyError"
                    @click="submitHandler(false)"
                  >
                    {{ $t('action.save') }}
                  </AtomButton>
                </div>
              </div>
              <div class="mb-2">
                <AtomButton
                  v-if="action === 'edit'"
                  variant="outline"
                  color="danger"
                  class="w-100"
                  @click="removeHandler"
                >
                  {{ $t('action.remove') }}
                </AtomButton>
              </div>
            </MoleculeCard>
          </div>
        </div>
      </div>
    </MoleculeForm>
  </div>
</template>

<script>
import { isEqual } from 'lodash'
import { required, maxLength } from 'vuelidate/lib/validators'

import MoleculePageHeader from '@/components/MoleculePageHeader'
import MoleculeCard from '@/components/MoleculeCard'
import MoleculeForm from '@/components/MoleculeForm'
import MoleculeImageInput from '@/components/MoleculeImageInput'
import AtomInput from '@/components/AtomInput'
import AtomTextarea from '@/components/AtomTextarea'
import AtomRichtext from '@/components/AtomRichtext'
import AtomSelect from '@/components/AtomSelect'
import AtomDatePicker from '@/components/AtomDatePicker'
import AtomChoose from '@/components/AtomChoose'
import AtomButton from '@/components/AtomButton'
import AtomDivider from '@/components/AtomDivider'

export default {
  components: {
    MoleculePageHeader,
    MoleculeCard,
    MoleculeForm,
    MoleculeImageInput,
    AtomInput,
    AtomTextarea,
    AtomRichtext,
    AtomSelect,
    AtomDatePicker,
    AtomChoose,
    AtomButton,
    AtomDivider,
  },

  data: () => ({
    article: {
      id: '',
      title: '',
      introtext: '',
      published: false,
      publishedAt: '',
      content: '',
      image: '',
      categoryKey: '',
    },

    state: {
      loading: true,
      submitLoading: false,
      dirty: false,
    },
  }),

  validations: {
    article: {
      title: { required, maxLength: maxLength(100) },
      introtext: { maxLength: maxLength(150) },
      content: { required },
      categoryKey: { required },
    },
  },

  computed: {
    action () {
      return this.$route.meta.action
    },

    pretitle () {
      return this.action === 'create' ? this.$t('pages.article.create.title') : this.$t('pages.article.edit.title')
    },

    categories () {
      return ['news', 'promotions'].map(category => ({
        label: this.$te(`category.list.${category}.label`) ? this.$t(`category.list.${category}.label`) : category,
        value: category,
      }))
    },
  },

  watch: {
    article: {
      handler () {
        this.state.dirty = !isEqual(this.$options.originalArticle, this.article)
      },
      deep: true,
    },
  },

  beforeRouteEnter (to, from, next) {
    next(async vm => {
      try {
        let article
        if (to.params.id) {
          article = await vm.$store.dispatch('getArticleDataById', to.params.id)
        }
        vm.pushArticleData(article)
        vm.state.loading = false
        vm.$v.$reset()
        next()
      } catch {
        next({ name: '404', replace: true })
      }
    })
  },

  methods: {
    async submitHandler (back) {
      if (this.$v.$invalid) {
        this.$v.$touch()
        return
      }

      try {
        this.state.submitLoading = true
        const articleId = await this.$store.dispatch(`${this.action}Article`, this.article)

        this.$options.originalArticle = { ...this.article }
        this.state.dirty = false

        this.$notify({
          title: this.$t('action.success'),
          text: this.$t(`article.success.${this.action}`),
          type: 'success',
        })

        if (back) {
          this.$router.push(`/article/${articleId}`)
        } else if (this.action === 'create') {
          this.$router.push(`/article/edit/${articleId}`)
        }
      } catch {} finally {
        this.state.submitLoading = false
      }
    },

    removeHandler () {
      try {
        this.$confirm({
          title: `${this.$t('action.warning')}!`,
          message: this.$t('article.action.remove'),
          status: 'danger',
          callback: async confirm => {
            if (confirm) {
              this.state.submitLoading = true
              await this.$store.dispatch('removeArticle', this.article.id)
              this.$router.replace(`/${this.article.categoryKey}/`)
              this.$notify({
                title: this.$t('action.success'),
                text: this.$t('article.success.remove'),
                type: 'success',
              })
              this.state.submitLoading = false
            }
          },
        })
      } catch {}
    },

    pushArticleData (article) {
      this.article = {
        id: article?.id || '',
        title: article?.title || '',
        introtext: article?.introtext || '',
        published: article?.published || false,
        publishedAt: article?.publishedAt || '',
        content: article?.content || '',
        image: article?.image || '',
        imageUrl: article?.image || '',
        categoryKey: article?.categoryKey || '',
      }

      this.$title = this.pretitle
      this.$options.originalArticle = { ...this.article }
    },
  },
}
</script>
